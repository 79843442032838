/*!
 * @package    Nevigen JShop Novaposhta Shipping Package
 * @version    1.3.1
 * @author     Nevigen.com - https://nevigen.com
 * @copyright  Copyright © Nevigen.com. All rights reserved.
 * @license    Proprietary. Copyrighted Commercial Software
 * @link       https://nevigen.com
 */

[data-nevigen-novaposhta-autocomplete] {
  position: relative;

  > ul {
    position: absolute;
    max-height: 226px;
    overflow-y: scroll;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0.5rem 0 0 0;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid rgba(33, 33, 33, 0.1);
    z-index: 1000;
    outline: none;

    > li {
      padding: 10px 20px;
      list-style: none;
      text-align: left;
      font-size: 16px;
      color: #212121;
      transition: all 0.1s ease-in-out;
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::selection {
        color: rgba(#ffffff, 0);
        background-color: rgba(#ffffff, 0);
      }

      &:hover {
        cursor: pointer;
        background-color: rgba(123, 123, 123, 0.1);
      }

      &[aria-selected="true"] {
        background-color: rgba(123, 123, 123, 0.1);
      }
    }
  }
}
